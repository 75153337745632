import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from "react-bootstrap";
import OfferCard from "../OfferCard/OfferCard";
import offers from "../../hooks/offers";
import "./OfferSection.css";
import CustomButton from "../CustomButton/CustomButton";
import SectionTitlePrimary from "../SectionTitlePrimary/SectionTitlePrimary";

const OfferSection = () => {
  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./OfferSection-ar.css");
    } else {
      import("./OfferSection.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  // Filter out the "Be the first to know" offer
  const filteredOffers = offers.filter(offer => offer.id !== "be-first-to-know");

  // Get the number of offers to display (at most 3)
  const offerCount = filteredOffers.length < 3 ? filteredOffers.length : 3;

  // Determine column width based on the number of offers
  const getColSize = (count) => {
    if (count === 1) return 12;
    if (count === 2) return 6;
    return 4; // for 3 or more offers
  };

  return (
    <Container>
      <div className="offer-section section-spacing">
        <SectionTitlePrimary subtitle="Offers" title={t("Latest Suzuki Offers")} />
        <Row className="justify-content-center">
          {filteredOffers.slice(0, 3).map((offer) => (
            <Col key={offer.id} md={getColSize(offerCount)}>
              <OfferCard offer={offer} />
            </Col>
          ))}
        </Row>
        <div className="mt-5">
          <CustomButton text={t("All Offers")} to="/offers" />
        </div>
      </div>
    </Container>
  );
};

export default OfferSection;
