import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./VehicleActionData.css";

const VehicleActionData = ({ vehicle }) => {

  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./VehicleActionData-ar.css");
    } else {
      import("./VehicleActionData.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);


  const [selectedVariant, setSelectedVariant] = useState(vehicle.variants[0]);
  const navigate = useNavigate();

  const handleVariantChange = (e) => {
    const variantName = e.target.value;
    const variant = vehicle.variants.find((v) => v.name === variantName);
    setSelectedVariant(variant);
  };

  const handleGetQuoteClick = () => {
    navigate("/get-quote", {
      state: {
        vehicleId: vehicle.id,
        selectedVariant: selectedVariant.name,
      },
    });
  };

  const getAccessoriesUrl = () => {
    
    if (vehicle.id === "baleno") {
      return "/360-config/car-model-selected.html?BZ";
    } else if (vehicle.id === "swift") {
      return "/360-config/car-model-selected.html?SR";
    } else if (vehicle.id === "ciaz") {
      return "/360-config/car-model-selected.html?CI";
    } else if (vehicle.id === "ertiga") {
      return "/360-config/car-model-selected.html?ER";
    } else if (vehicle.id === "grand-vitara") {
      return "/360-config/car-model-selected.html?GV";
    } else if (vehicle.id === "dzire") {
      return "/360-config/car-model-selected.html?DI";
    } else if (vehicle.id === "fronx") {
      return "/360-config/car-model-selected.html?FR";
    }
  };

  const shouldHideAccessoriesButton = ["jimny", "jimny-5-doors", "carry", "eeco"].includes(
    vehicle.id.toLowerCase()
  );

  return (
    <div className="vehicle-action-data section-spacing justify-content-center">
      <Container>
        <Row className="vehicle-action-data-row align-items-center">
          <Col md={12} lg={2}>
            <p>{t("Starting From")}</p>
            <h3>SAR {selectedVariant.price}</h3>
            <span className="imp-msg">{t("Inclusive of VAT*")}</span>
          </Col>
          <Col md={12} lg={3}>
            <p>{t("Model")}</p>
            <Form.Group controlId="variantSelect">
              <Form.Select
                as="select"
                value={selectedVariant.name}
                onChange={handleVariantChange}
                className="variant-dropdown-select"
              >
                {vehicle.variants.map((variant, index) => (
                  <option key={index} value={variant.name}>
                    {variant.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={12} lg={2} className="text-md-center"></Col>
          <Col md={12} lg={2} className="text-md-center">
            <Button
              className="vehicle-action-link"
              href="/book-test-drive"
            >
              {t("Book a Test Drive")}
            </Button>
            <div>
            <a
              href={vehicle.brochure}
              target="_blank"
              className="vehicle-action-link"
              rel="noreferrer"
            >
              {t("Download Brochure")}
            </a>
            </div>
            
          </Col>
          <Col lg={3}>
            <Button className="custom-vehicle-detail-button mt-md-2 mb-md-2" onClick={handleGetQuoteClick} >
              {t("Get a Quote")}
              <i className={`${i18n.language === "ar" ? "jam jam-arrow-up-left text-white" : "jam jam-arrow-up-right text-white"}`}></i>
            </Button>

            {!shouldHideAccessoriesButton && (
              <Button className="custom-vehicle-detail-button mt-md-2 mb-md-2" onClick={() =>
                window.open(getAccessoriesUrl(), "_blank", "noopener,noreferrer")
              }>
              {t("Explore Accessories")}
              <i className={`${i18n.language === "ar" ? "jam jam-arrow-up-left text-white" : "jam jam-arrow-up-right text-white"}`}></i>
            </Button>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VehicleActionData;
