import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import OfferCard from "../../components/OfferCard/OfferCard";
import SectionTitlePrimary from "../../components/SectionTitlePrimary/SectionTitlePrimary";
import CallToAction from "../../components/CallToAction/CallToAction";
import offers from "../../hooks/offers";
import "./OffersPage.css";

const OffersPage = () => {
  const { t, i18n } = useTranslation();

  const loadCss = (lng) => {
    if (lng === "ar") {
      // import("./OffersPage-ar.css");
    } else {
      import("./OffersPage.css");
    }
  };

  useEffect(() => {
    loadCss(i18n.language);
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  // Filter out the "Be the first to know" offer
  const filteredOffers = offers.filter(offer => offer.id !== "be-first-to-know");

  // Get the number of offers to display
  const offerCount = filteredOffers.length < 3 ? filteredOffers.length : 3;

  // Determine column width based on the number of offers
  const getColSize = (count) => {
    if (count === 1) return 12;
    if (count === 2) return 6;
    return 4; // for 3 or more offers
  };

  return (
    <>
      <div className="section-spacing">
        <Container className="offers-page">
          <SectionTitlePrimary subtitle="All Offers" title={t("All Suzuki Offers")} />
          <Row className="justify-content-center">
            {filteredOffers.map((offer) => (
              <Col key={offer.id} md={getColSize(offerCount)}>
                <OfferCard offer={offer} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <div>
        <CallToAction />
      </div>
    </>
  );
};

export default OffersPage;
